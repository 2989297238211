import Image from "next/image";
import React, { useEffect, useState } from "react";
import classNames from "src/helpers/classNames";
// import mixpanelTracker from "src/helpers/mixpanelTracking";
import EventTracker from "src/helpers/event-tracking/eventTrackingManager";

import PlayIcon from "./PlayIcon";

type VideoPlayerProps = {
  videoId: string;
  cover: string;
  alt: string;
  imageSize?: string;
  wide?: boolean;
  text?: { name: string; caption: string };
  className?: string;
  videoTrackingName?: string;
  square?: boolean;
  rounded?: boolean;
};

const VideoPlayer = ({
  videoId,
  cover,
  alt,
  imageSize = "50vw",
  wide = false,
  square = false,
  rounded = false,
  text,
  className = "",
  videoTrackingName = "",
}: VideoPlayerProps) => {
  const [videoVisible, setVideoVisible] = useState(false);

  useEffect(() => {
    setVideoVisible(false);
  }, [videoId]);

  return (
    <div
      className="m-auto w-full max-w-2xl"
      onClick={() => {
        EventTracker.clickOnVideo(
          videoTrackingName ? videoTrackingName + ` (${videoId})` : videoId
        );
        // mixpanelTracker.clickOnVideo(
        // 	videoTrackingName ? videoTrackingName + ` (${videoId})` : videoId
        // );
      }}
    >
      <div
        className={classNames(
          "relative",
          wide
            ? "aspect-h-8 aspect-w-16"
            : square
            ? "aspect-h-1 aspect-w-1"
            : "aspect-h-9 aspect-w-16",
          className
        )}
      >
        {videoVisible && (
          <iframe
            src={`https://www.youtube.com/embed/${videoId}?autoplay=1&rel=0`}
            className={classNames(
              "absolute left-0 top-0 h-full w-full",
              rounded ? "rounded-lg" : ""
            )}
            frameBorder={0}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; full-screen;"
            title="video"
          />
        )}
        {!videoVisible && (
          <button
            className="group absolute inset-0 flex items-center justify-center"
            aria-label="video_player"
            type="button"
            onClick={() => setVideoVisible(true)}
          >
            <Image
              src={cover}
              alt={alt}
              fill
              sizes={imageSize}
              className={classNames(
                "object-cover",
                rounded ? "rounded-lg" : ""
              )}
              priority={false}
              quality={100}
              placeholder="empty"
            />

            {text && (
              <div className="absolute inset-0 flex flex-col items-start justify-end bg-gray-900/20 p-2 font-semibold text-white">
                <p className="">{text.name}</p>
                <p className="text-sm">{text.caption}</p>
              </div>
            )}

            <div className="relative m-auto bg-gray-900 p-1.5 transition-all group-hover:rounded-3xl">
              <PlayIcon className="h-9 w-9" aria-hidden="true" />
            </div>
          </button>
        )}
      </div>
    </div>
  );
};

export default VideoPlayer;
