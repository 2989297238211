import { useTranslations } from "next-intl";
import Link from "next/link";
import React from "react";
import ThemeLink from "src/components/link/ThemeLink";
import VideoPlayer from "src/components/player/VideoPlayer";

type Props = {};

const HowItWorks = (props: Props) => {
  const t = useTranslations("HowItWorks");

  return (
    <>
      <h2 className="mb-5 text-4xl font-bold sm:px-0">
        {t("how_does_it_work")}
      </h2>

      <div className="lg:flex lg:items-center lg:justify-between">
        <p className="mb-5">{t("check_latest_video")}</p>

        <Link href={"/shop-all"} passHref legacyBehavior>
          <ThemeLink className="mb-6 shrink-0">
            {t("discover_our_products_button")}
          </ThemeLink>
        </Link>
      </div>

      <div className="bg-beige-400 lg:px-10 lg:pt-24 lg:pb-14">
        <VideoPlayer
          videoTrackingName="How it work (Section)"
          videoId="TN5Bzllrq3g"
          cover="/images/thumbnail-how-it-works.webp"
          alt="Show a video that explains how Shemsi works"
          imageSize="(min-width: 64em) 50vw,
                    100vw"
        />

        <p className="hidden text-beige-700 lg:mt-12 lg:block lg:text-center lg:font-bold">
          {t("discover_shemsi_sun_emoji")}
        </p>
      </div>
    </>
  );
};

export default HowItWorks;
