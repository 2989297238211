import Link from "next/link";
import { memo } from "react";
import ThemeLink from "src/components/link/ThemeLink";
import { useTranslations } from "next-intl";

type Props = {};

function HomeBar({}: Props) {
  const t = useTranslations("Homepage");

  return (
    <div className="flex flex-col lg:flex-row lg:items-center lg:justify-center">
      <p className="mb-3 text-center text-xl font-medium md:text-start lg:mb-0 lg:text-2xl lg:leading-relaxed lg:me-8">
        <span className="md:hidden">{t("homebar_mobile")}</span>
        <span className="hidden md:inline">{t("homebar_tablet")}</span>
      </p>
      <Link href={"/shop-all"} passHref legacyBehavior>
        <ThemeLink className="shrink-0">{t("shop_now")}</ThemeLink>
      </Link>
    </div>
  );
}

export default memo(HomeBar);
