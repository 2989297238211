import { useTranslations } from "next-intl";
import Image from "next/image";
import Absorptions from "src/components/absorptions/Absorptions";
import Link from "next/link";
import spaceToUnderscore from "src/helpers/strings/spaceToUnderscore";
import absorptionsData from "src/config/absorptions.json";
import find from "lodash/find";
import useMainProducts from "src/hooks/useMainProducts";

type AbsorptionLegendProps = {};

const AbsorptionLegend = ({}: AbsorptionLegendProps) => {
  const t = useTranslations("AbsorptionLegend");
  const mainProducts = useMainProducts();

  return (
    <div className="flex justify-start bg-white p-4 lg:mb-3 lg:items-center lg:justify-center xl:py-6">
      <section className="flex w-full flex-col lg:space-y-3">
        <div className="mb-2 hidden justify-center text-2xl font-bold lg:mb-0 lg:flex lg:gap-4 lg:text-2xl lg:font-semibold">
          <div className="flex w-2/6 justify-center lg:w-2/6">
            {t("absorption")}
          </div>
          <div className="hidden w-2/6 justify-center lg:inline-flex">
            {t("equivalent")}
          </div>
          <div className="hidden w-2/6 justify-center lg:inline-flex">
            {t("products")}
          </div>
        </div>
        <div>
          <div className="flex flex-col">
            {absorptionsData.map((absorption, index, array) => {
              const { level, pads } = absorption;

              return (
                <div key={level}>
                  <section className="flex w-full  flex-col items-center  justify-center gap-4 last:border-none lg:flex-row">
                    <div className=" text-md flex items-center justify-center font-semibold md:w-2/6 md:justify-center lg:bg-inherit lg:text-lg">
                      <div className=" flex justify-center gap-4">
                        <p className="">{t(spaceToUnderscore(level))}</p>
                        <Absorptions
                          className=""
                          value={level || ""}
                          imageClassName="w-3"
                        />
                      </div>
                    </div>
                    <div className="flex w-full flex-col items-center gap-1.5 md:w-2/6 md:justify-center lg:bg-inherit lg:text-lg">
                      <div className="text-md font-medium lg:hidden">
                        <p>{t("equivalent")}</p>
                      </div>
                      <div className="flex items-center justify-center gap-2 font-thin lg:bg-inherit ">
                        {[...Array(pads)].map((pad, index) => {
                          return (
                            <Image
                              key={index}
                              className="h-auto "
                              src="/images/pad-icon-cropped.svg"
                              alt="pad-icon"
                              width={25}
                              height={25}
                            />
                          );
                        })}
                      </div>
                    </div>
                    <div className="flex flex-col gap-1.5 xs:w-3/6 lg:w-2/6 lg:bg-inherit">
                      <div className="text-md flex flex-row items-center justify-center font-medium lg:hidden lg:flex-col ">
                        <p>{t("products")}</p>
                      </div>
                      {/* <ul /> here */}
                      <ul className="flex justify-center gap-4">
                        {mainProducts &&
                          mainProducts
                            .filter((product: any) => {
                              const productOption = find(
                                product.options,
                                (f) => f.name == "Absorption"
                              );
                              if (productOption) {
                                if (productOption.values.includes(level))
                                  return true;
                              }

                              return false;
                            })
                            .map((product: any) => {
                              return (
                                <li className="" key={product.id}>
                                  <Link
                                    href={{
                                      pathname: `/products/${product.handle}`,
                                      query: {
                                        Absorption: level,
                                      },
                                    }}
                                  >
                                    <>
                                      <Image
                                        className="h-16 w-16 rounded-full object-cover shadow-md"
                                        src={product.images[0].url}
                                        alt={product.title}
                                        width={0}
                                        height={0}
                                        sizes="4rem"
                                      />
                                      <span className="mt-1 block text-center text-xs">
                                        {t(spaceToUnderscore(product.title))}
                                      </span>
                                    </>
                                  </Link>
                                </li>
                              );
                            })}
                      </ul>
                    </div>
                  </section>
                  {index !== array.length - 1 && <hr className="my-4 " />}
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
};

export default AbsorptionLegend;
