import { memo, useContext, useEffect, useState } from "react";
import { StarIcon } from "@heroicons/react/24/solid";
import Image from "next/image";
import Link from "next/link";
import { useTranslations } from "next-intl";
import Aos from "aos";
import "aos/dist/aos.css";
import { StoreMetadataContext } from "src/context/StoreMetadataContext";
import find from "lodash/find";

import bannerImage from "public/images/banner.webp";
import homepageModelImage from "public/images/key-features.webp";
import useCountryData from "src/hooks/useCountryData";

const HomepageHero = () => {
  const countryData = useCountryData();

  const t = useTranslations("Homepage");

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      once: true,
      disable: "mobile",
    });
  });

  const storeMetadata = useContext(StoreMetadataContext);

  const bigProduct =
    countryData &&
    storeMetadata &&
    find(storeMetadata.products, (product: any) => product.id == "gid://shopify/Product/" + countryData.homeHeroImage);

  const [ratingsCount, setRatingsCount] = useState("");

  useEffect(() => {
    if (countryData) {
      const rating = countryData.getTotalReviewsCount();
      if (rating) setRatingsCount(rating);
    }
  }, [countryData]);

  return (
    <div className="my-0 md:mb-4">
      {/* tagline class is added for e2e testing */}
      <p className="tagline my-4 text-3xl font-semibold md:mt-0 lg:mb-6 lg:text-4xl">{t("tagline")}</p>
      <div className="flex flex-col lg:grid lg:grid-cols-5 lg:gap-4">
        <div className="order-1 col-span-2 lg:order-none">
          <div className="hidden lg:block">
            <Link href={bigProduct?.handle ? `/products/${bigProduct.handle}` : "/shop-all"}>
              <Image
                className="w-full"
                src={homepageModelImage}
                alt="Model image"
                width={0}
                height={0}
                sizes="30rem"
                placeholder="blur"
              />
            </Link>
          </div>
          <div className="order-2 lg:block lg:text-2xl">
            <p
              className="mt-3 text-center text-2xl font-semibold sm:mt-4 sm:text-xl md:text-2xl lg:block lg:text-start"
              data-aos="fade-right"
              data-aos-delay=""
            >
              {t("never_again_pads")}
            </p>
            <div
              className="mt-3 flex justify-center font-normal leading-tight sm:text-lg sm:leading-normal lg:justify-start"
              data-aos="fade-right"
              data-aos-delay="100"
            >
              <Image
                src="/images/flags/france.svg"
                width={24}
                height={18}
                alt="France flag"
                className="me-2"
                priority
              />
              <p className="me-2">{t("designed_in_france")}</p>
              {countryData?.code === "EG" && (
                <>
                  <Image
                    src="/images/flags/egypt.svg"
                    width={24}
                    height={10}
                    alt="Egypt flag"
                    className="me-2"
                    priority
                  />
                  {t("made_in_egypt")}
                </>
              )}
            </div>
            <div className="flex justify-center pt-4 lg:block lg:pt-8" data-aos="fade-right" data-aos-delay="300">
              <div className="inline-flex items-center rounded-3xl bg-beige-600 px-4 py-2.5">
                <ul className="me-2 flex">
                  {Array(5)
                    .fill(null)
                    .map((_, index) => (
                      <li key={index}>
                        <StarIcon className="h-5 w-5" fill="#ffc107" />
                      </li>
                    ))}
                </ul>
                <span className="text-lg font-semibold lg:text-xl" suppressHydrationWarning={true}>
                  {t("n_stars", { count: ratingsCount })}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="col-span-3">
          <Link
            href={
              /*smallProduct?.handle
                  ? `/products/${smallProduct.handle}`
                  : "/shop-all"*/
              "/shop-all"
            }
          >
            <Image
              className="h-full w-full object-cover"
              src={bannerImage}
              alt="Shop All"
              width={0}
              height={0}
              sizes="(min-width: 64em) 46rem,
                       100vw"
              placeholder="blur"
              priority
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default memo(HomepageHero);
