import Link from "next/link";
import React, { useState } from "react";
import VideoPlayer from "src/components/player/VideoPlayer";
import CustomPlayIcon from "src/components/icon/PlayIcon";
import { useTranslations } from "next-intl";
import useRtl from "src/hooks/useRtl";
import ArrowIcon from "src/components/icon/ArrowIcon";

type HowToUseProps = {};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const HowToUse = (props: HowToUseProps) => {
  const t = useTranslations("HowToUse");
  const rtl = useRtl();

  const usageTutorials = [
    {
      text: t("what_is_shemsi"),
      cover: "/images/product-guide-4.webp",
      videoId: "sDM8Zq1klu8",
    },
    {
      text: t("how_does_shemsi_work"),
      cover: "/images/product-guide-2.webp",
      videoId: "wkx3a4zzZuk",
    },
    {
      text: t("how_to_clean_shemsi"),
      cover: "/images/product-guide-3.webp",
      videoId: "gSyg8VdEO1I",
    },
  ];

  const [activeTutorial, setActiveTutorial] = useState(0);

  const tutorial = usageTutorials[activeTutorial];
  return (
    <div className="flex flex-col lg:flex-row lg:items-center">
      <div className="mb-5 flex-1 me-10 lg:mb-0">
        <h3 className="title-2">{t("how_to_use_a_shemsi")}</h3>
        <ul className="my-5">
          {usageTutorials.map((tutorial, index) => (
            <li key={tutorial.text} className="mb-5 text-start">
              <button
                type="button"
                className="flex items-center"
                onClick={() => setActiveTutorial(index)}
              >
                <span
                  className={classNames(
                    activeTutorial === index ? "rounded-3xl" : "",
                    "bg-gray-900 p-1.5 me-3"
                  )}
                >
                  <CustomPlayIcon className="w-4" />
                </span>
                <span
                  className={classNames(
                    activeTutorial === index ? "font-bold" : "",
                    "py-2",
                    "text-start"
                  )}
                >
                  {tutorial.text}
                </span>
              </button>
            </li>
          ))}
        </ul>
        <Link href="/faq" className="group flex items-center py-3">
          <>
            {t("see_all_questions")}
            <ArrowIcon
              rtl={rtl}
              className="ms-3 w-6 transition-all group-hover:ms-6"
            />
          </>
        </Link>
      </div>

      <div className="relative w-full lg:w-1/2">
        <VideoPlayer
          videoTrackingName="How to use (section)"
          videoId={tutorial.videoId}
          cover={tutorial.cover}
          alt={tutorial.text}
          imageSize="(min-width: 64em) 40rem,
						100vw"
        />
      </div>
    </div>
  );
};

export default HowToUse;
