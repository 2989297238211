import { GetStaticProps } from "next";
import { Collection } from "src/types/storefront";
import { Locale } from "src/types/localization/Locale";
import getCountryCodeFromLocale from "src/helpers/localization-helpers/getCountryCodeFromLocale";
import Homepage from "src/sections/homepage/homepage-versions/Homepage";
import getCollectionById from "src/apis/storefront-latest-version/collections/getCollectionById";
import countries from "src/config/countries/countries";
import getTranslations from "src/helpers/localization-helpers/getTranslations";

interface NewHomeProps {}

function NewHome({}: NewHomeProps) {
  return <Homepage />;
}

export const getStaticProps: GetStaticProps<{
  // storeMetadata: Collection;
  // newPriceCollection: Collection | null;
}> = async ({ locale }) => {
  if (!locale) {
    throw new Error(`locale is not passed to getStaticProps`);
  }

  if (!(locale in Locale)) locale = Locale["ar-EG"];

  // const countryCode = getCountryCodeFromLocale(locale as Locale);
  // const countryData = countries[countryCode];

  // const storeMetadata = await getCollectionById(
  //   countryCode,
  //   countryData.collections.COLLECTION_ALL_ACTIVE_PRODUCTS.id,
  //   true
  // );

  // let newPriceCollection: Collection | null = null;
  // if (countryData.deploymentConfig.COLLECTION_NEW_PRICES) {
  //   newPriceCollection = await getCollectionById(
  //     countryData.code,
  //     countryData.deploymentConfig.COLLECTION_NEW_PRICES,
  //     true
  //   );
  // }

  const translations = getTranslations(locale);

  return {
    props: {
      // storeMetadata: storeMetadata,
      // newPriceCollection,
      translations,
    },
  };
};

export default NewHome;
