import { memo, useEffect, useState } from "react";
import Image from "next/image";
import classNames from "src/helpers/classNames";
import { useTranslations } from "next-intl";
import useCountryCode from "src/hooks/useCountryCode";

type Props = {};

const MediaMentions = (props: Props) => {
  const t = useTranslations("Homepage");
  const countryCode = useCountryCode();

  const [activeIndex, setActiveIndex] = useState(0);

  const feedback = [
    {
      mediaName: "Maman",
      src: "/images/media-mentions/maman-plus.webp",
      text: t("press_maman"),
    },
    {
      mediaName: "challenge",
      src: "/images/media-mentions/challenge.webp",
      text: t("press_challenge"),
    },
    {
      mediaName: "hola",
      src: "/images/media-mentions/hola.webp",
      text: t("press_hola"),
    },
    {
      mediaName: "brut",
      src: "/images/media-mentions/brut.webp",
      text: t("press_brut"),
    },
    {
      mediaName: "CairoScene",
      src: "/images/media-mentions/cairo-scene.webp",
      text: t("press_cairoscene"),
    },
    {
      mediaName: "We Love Buzz",
      src: "/images/media-mentions/we-love-buzz.webp",
      text: t("press_welovebuzz"),
    },
    {
      mediaName: "Fustany",
      src: "/images/media-mentions/fustany.webp",
      text: t("press_fustany"),
    },
    {
      mediaName: "AWM News",
      src: "/images/media-mentions/awm-news.webp",
      text: t("press_awm_news"),
    },
  ];

  const otherCountriesFeedback = [
    {
      mediaName: "Maman",
      src: "/images/media-mentions/maman-plus.webp",
      text: t("press_maman"),
    },
    {
      mediaName: "challenge",
      src: "/images/media-mentions/challenge.webp",
      text: t("press_challenge"),
    },
    {
      mediaName: "hola",
      src: "/images/media-mentions/hola.webp",
      text: t("press_hola"),
    },
    {
      mediaName: "brut",
      src: "/images/media-mentions/brut.webp",
      text: t("press_brut"),
    },
    {
      mediaName: "Fustany",
      src: "/images/media-mentions/fustany.webp",
      text: t("press_fustany"),
    },
    {
      mediaName: "AWM News",
      src: "/images/media-mentions/awm-news.webp",
      text: t("press_awm_news"),
    },
  ];

  useEffect(() => {
    const intervalId = setInterval(function changeActiveIndex() {
      setActiveIndex((prevActiveIndex) => {
        const lastIndex =
          prevActiveIndex ===
          (countryCode === "EG"
            ? feedback.length - 1
            : otherCountriesFeedback.length - 1);
        return lastIndex ? 0 : prevActiveIndex + 1;
      });
    }, 5000);

    return () => clearInterval(intervalId);
  }, [activeIndex]);

  const handleClick = (index: number) => {
    setActiveIndex(index);
  };

  useEffect(
    function resetOnDeploymentChange() {
      setActiveIndex(0);
    },
    [countryCode]
  );

  return (
    <div className="">
      <h2 className="sr-only">{t("media_feedback")}</h2>
      <p className="text-center text-lg">{t("shemsi_in_press")}</p>
      <p className="text-center text-2xl font-semibold text-gray-900 md:text-3xl">
        “
        {countryCode === "EG"
          ? feedback[activeIndex].text
          : otherCountriesFeedback[activeIndex]
          ? otherCountriesFeedback[activeIndex].text
          : otherCountriesFeedback[otherCountriesFeedback.length - 1].text}
        ”
      </p>
      <ul
        className={`grid pb-2 ${
          countryCode === "EG"
            ? "grid-cols-4 sm:grid-cols-8"
            : "grid-cols-3 sm:grid-cols-6"
        }`}
      >
        {countryCode === "EG"
          ? feedback.map((item, index, array) => (
              <li
                key={item.mediaName}
                className={classNames(
                  "" /* relative mx-2 mt-2 shrink-0 sm:mx-5 sm:mt-2.5*/ /*,
				index === 0 ? "ms-auto" : "",
				index === length - 1 ? "me-auto" : ""*/
                )}
              >
                <button
                  type="button"
                  className={classNames(
                    activeIndex !== index ? "opacity-50" : "",
                    "h-12 min-h-full transition hover:opacity-100"
                  )}
                  onClick={() => handleClick(index)}
                >
                  <Image
                    className="h-auto w-full"
                    src={item.src}
                    alt={item.mediaName}
                    width={0}
                    height={0}
                    // sizes="12rem"
                    sizes="(min-width: 64em) 10rem,
                        7rem"
                  />
                </button>
              </li>
            ))
          : otherCountriesFeedback.map((item, index, array) => (
              <li
                key={item.mediaName}
                className={classNames(
                  "" /* relative mx-2 mt-2 shrink-0 sm:mx-5 sm:mt-2.5*/ /*,
				index === 0 ? "ms-auto" : "",
				index === length - 1 ? "me-auto" : ""*/
                )}
              >
                <button
                  type="button"
                  className={classNames(
                    activeIndex !== index ? "opacity-50" : "",
                    "h-12 min-h-full transition hover:opacity-100"
                  )}
                  onClick={() => handleClick(index)}
                >
                  <Image
                    className="h-auto w-full"
                    src={item.src}
                    alt={item.mediaName}
                    width={0}
                    height={0}
                    // sizes="12rem"
                    sizes="(min-width: 64em) 10rem,
                        7rem"
                  />
                </button>
              </li>
            ))}
      </ul>
    </div>
  );
};

export default memo(MediaMentions);
