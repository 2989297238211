import { useTranslations } from "next-intl";
import useLanguage from "src/hooks/useLanguage";
import HomepageHero from "src/sections/banner/HomepageHero";
import HomeBar from "src/sections/home-bar/HomeBar";
import HowItWorks from "src/sections/how-it-works/HowItWorks";
import AbsorptionLegend from "src/sections/absorption-brief/AbsorptionBrief";
import HowToUse from "src/sections/how-to-use/HowToUse";
import Badges from "src/sections/badges/Badges";
import KeyFeatures from "src/sections/key-features/KeyFeatures";
import Image from "next/image";
import Layout from "src/sections/layout/Layout";
import Container from "src/sections/container/Container";
import MediaMentions from "src/sections/media-feedback/MediaMentions";
import useProducts from "src/hooks/useProducts";
import useCountryData from "src/hooks/useCountryData";
import { CountryCode } from "src/types/localization/CountryCode";
import MultiCollectionViewer from "src/components/products/MultiCollectionViewer";
import KoalaMultiCollectionViewer from "src/components/products/KoalaMultiCollectionViewer";

const Homepage = () => {
  const t = useTranslations("Homepage");
  const countryData = useCountryData();
  const language = useLanguage();

  return (
    <Layout pageTitle={t("home")} showStickyFooterNavigationButton={true} description={t("shemsi_homepage")}>
      <div className="overflow-hidden">
        <Container className="pt-0">
          <HomepageHero />
        </Container>
        <div className="">
          <Container>
            <HomeBar />
          </Container>
        </div>
        <div className="bg-beige-50">
          <Container>
            <section>
              <MediaMentions />
            </section>
          </Container>
        </div>

        <section>
          <Container>
            <div className="flex flex-col md:flex-row">
              <div className="flex h-auto w-full justify-center md:w-1/2">
                <Image
                  width={387}
                  height={300}
                  alt="Technology"
                  src={`/images/homepage/technology-${language.value === "ar" ? "ar" : "en"}.webp`}
                ></Image>
              </div>
              <div className="my-auto w-full md:w-1/2 md:ps-5 lg:ps-0">
                <h2 className="title-1 mb-5">{t("unique_proven_technology")}</h2>
                <p>{t("shemsi_uses_french_technology")}</p>
              </div>
            </div>
          </Container>
        </section>

        <section className="">
          <Container>
            <div className="flex  flex-col-reverse md:flex-row">
              <div className="my-auto w-full md:w-1/2">
                <h2 className="title-1 mb-5">{t("stay_fresh")}</h2>
                <p>{t("our_technology")}</p>
              </div>
              <div className="mx-auto flex w-1/2 justify-center pb-5 md:w-1/2 md:pb-0">
                <Image
                  width={258}
                  height={258}
                  alt="Stay fresh"
                  src={`/images/homepage/12-hours-${language.value === "ar" ? "ar" : "en"}.webp`}
                />
              </div>
            </div>
          </Container>
        </section>

        <section className="">
          <Container>
            <div className="flex  flex-col md:flex-row">
              <div className="mx-auto flex w-1/2 justify-center pb-5 md:w-1/2 md:pb-0">
                <Image width={258} height={258} alt="International" src={`/images/homepage/international.webp`} />
              </div>
              <div className="my-auto w-full md:w-1/2 md:ps-5 lg:ps-0">
                <h2 className="title-1 mb-5">{t("big_in_europe")}</h2>
                <p>{t("millions_being_sold")}</p>
              </div>
            </div>
          </Container>
        </section>
        {countryData?.code !== CountryCode.US && (
          <Container className="md:my-10">
            <section>
              <HowItWorks />
            </section>
          </Container>
        )}

        <div>
          <Container className="mt-5 md:pb-4">
            <section>
              <div className="md:mb-5">
                <h2 className="title-1 text-center">{t("absorption_levels_title")}</h2>
                <p className="mt-2 text-center md:mx-auto md:my-5 lg:w-1/2">{t("absorption_levels_body")}</p>
              </div>

              <AbsorptionLegend />
            </section>
          </Container>
        </div>

        <Container>
          {countryData && countryData.oms == "koala" ? <KoalaMultiCollectionViewer /> : <MultiCollectionViewer />}
        </Container>

        {countryData?.code !== CountryCode.US && (
          <div className="bg-beige-50 ">
            <Container>
              <article>
                <HowToUse />
              </article>
            </Container>
          </div>
        )}
        <Container className="md:my-15">
          <Badges />
        </Container>

        {countryData?.code !== CountryCode.US && (
          <div className="bg-beige-50">
            <Container>
              <section>
                <KeyFeatures />
              </section>
            </Container>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Homepage;
