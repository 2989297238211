import { useTranslations } from "next-intl";
import Image from "next/image";
import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import ThemeLink from "src/components/link/ThemeLink";
import Link from "next/link";
import classNames from "src/helpers/classNames";
import useCountryCode from "src/hooks/useCountryCode";
import { CountryCode } from "src/types/localization/CountryCode";

type BadgesProps = {};

const Badges = (props: BadgesProps) => {
  const t = useTranslations("Badges");
  const countryCode = useCountryCode();

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      once: true,
      disable: "mobile",
    });
  });

  let disocuntBadgeVisible = false;
  if (
    countryCode &&
    countryCode !== CountryCode.LB &&
    countryCode !== CountryCode.EG
  ) {
    disocuntBadgeVisible = true;
  }

  return (
    <div className="relative lg:flex">
      {/* Badge */}
      <div className="bg-beige-600 px-5 py-6 lg:flex lg:w-2/3 lg:shrink-0 lg:items-center lg:justify-between lg:me-10">
        <div className="lg:w-1/2" data-aos="fade-right">
          <p className="mb-5 text-2xl font-semibold">
            {t("proudly_made_in_egypt")}
          </p>

          <p className="mb-5 font-medium">{t("proudly_made_in_egypt_body")}</p>

          <Link href={"/shop-all"} passHref legacyBehavior>
            <ThemeLink className="relative z-10 w-full">
              {t("shop_now")}
            </ThemeLink>
          </Link>
        </div>

        <div
          className="relative mt-2 h-96 lg:mt-0 lg:h-56 lg:w-1/2"
          data-aos="zoom-out"
          data-aos-delay="50"
        >
          <Image
            className="object-contain lg:object-right"
            src={"/images/shemsi-book.webp"}
            alt="Shemsi package"
            aria-hidden="true"
            layout="fill"
            sizes="(min-width: 64em) 14rem,
               24rem"
          />
        </div>
      </div>

      <div className="lg:flex lg:flex-col lg:justify-between">
        {/* Badge */}
        <div
          className="mt-5 bg-gray-900 px-4 py-5 text-white lg:mt-0"
          data-aos="fade-down-left"
          data-aos-delay="100"
        >
          <Image
            className="mb-3 w-28"
            src={"/images/express.webp"}
            alt="Express"
            width={0}
            height={0}
            sizes="8rem"
          />

          <p> {t("delivery_in_all_egypt")}</p>
        </div>

        {/* Badge */}
        {disocuntBadgeVisible && (
          <div
            className={classNames(
              "mt-5 border-2 border-gray-900 bg-white px-4 py-5 lg:mt-3"
            )}
            data-aos="fade-up-left"
            data-aos-delay="150"
          >
            <p className="mb-3 text-2xl font-semibold">{t("badge")}</p>

            <p>{t("badge_body")}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Badges;
