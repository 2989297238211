import { useTranslations } from "next-intl";
import Image from "next/image";
import React, { useEffect } from "react";
import VideoPlayer from "src/components/player/VideoPlayer";
import Aos from "aos";
import "aos/dist/aos.css";
import useRtl from "src/hooks/useRtl";

type KeyFeaturesProps = {};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const KeyFeatures = (props: KeyFeaturesProps) => {
  const t = useTranslations("KeyFeatures");
  const rtl = useRtl();

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      once: true,
      disable: "mobile",
    });
  });

  const features = [
    {
      text: t("reusable"),
      featureName: "reusable",
      cover: "/images/thumbnail-reusable.webp",
      videoId: "wkx3a4zzZuk",
    },
    {
      text: t("absorbent"),
      featureName: "absorbent",
      cover: "/images/thumbnail-absorbent.webp",
      videoId: "0WZyXeiOl1o",
    },
    {
      text: t("washable"),
      featureName: "washable",
      cover: "/images/thumbnail-washable.webp",
      videoId: "gSyg8VdEO1I",
    },
  ];

  return (
    <div className="lg:flex lg:gap-10">
      {/* <div
				className="flex flex-col justify-center py-8 sm:py-10 sm:px-6 lg:w-1/2 lg:py-12 lg:px-8"
				data-aos="fade-up"
			> */}
      <div
        className="flex flex-col justify-center py-8 sm:py-10  lg:w-1/2 lg:py-12 "
        data-aos="fade-up"
      >
        {/* <h2 className="mb-7 px-4 text-3xl font-bold text-gray-900 sm:px-0"> */}
        <h2 className="mb-7 flex justify-center text-3xl font-bold text-gray-900 sm:px-0 lg:block">
          {t("discover_period_underwear")}
        </h2>
        <Image
          // className="object-contain lg:object-left"
          className={classNames(
            "h-auto w-full object-contain",
            rtl ? "lg:object-right" : "lg:object-left"
          )}
          src={"/images/key-features.webp"}
          alt=""
          // sizes="600px"
          sizes="(min-width: 64em) 38rem,
               100vw"
          width={0}
          height={0}
        />
      </div>

      <ul className="flex flex-col bg-beige-700 py-8 px-4 sm:py-10 sm:px-6 lg:w-1/2 lg:py-12 lg:px-8">
        {features.map((feature, index) => (
          <li
            key={feature.text}
            className={classNames(
              index % 2 !== 0 ? "self-end" : "",
              index < features.length - 1 ? "mb-7" : "",
              "flex w-72 flex-col"
            )}
            data-aos="fade-left"
          >
            <VideoPlayer
              videoTrackingName={"Feature " + feature.featureName}
              videoId={feature.videoId}
              cover={feature.cover}
              alt={""}
              imageSize="300px"
            />
            <p className="bg-white px-4 py-2 text-2xl font-semibold text-end">
              {feature.text}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default KeyFeatures;
